import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";

import Api from "../../Services/Api";

import "./index.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      username: "",
      password: "",
      credentialError: false,
      serverError: false,
      isStopped: false,
      isPaused: false,
    };
  }

  authenticate = async () => {
    this.setState({ requesting: true, credentialError: false, serverError: false });
    try {
      let { username, password } = this.state;
      let loginRequest = await Api.post("/auth", { username, password });
      if (loginRequest.data.ok === true) {
        localStorage.setItem("user_token", loginRequest.data.token);
        localStorage.setItem("user_name", loginRequest.data.user);
        localStorage.setItem("user_type", loginRequest.data.type);
        window.location.replace("/");
      }
    } catch (error) {
      if (!error.response || (error.response.status && error.response.status !== 401)) return this.setState({ requesting: false, serverError: true });
      this.setState({ requesting: false, credentialError: true });
    }
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="bg-gradient-primary login-container d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block p-5">
                      <Lottie options={defaultOptions} height={400} width={400} isStopped={this.state.isStopped} isPaused={this.state.isPaused} />
                    </div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Entre com suas credenciais</h1>
                        </div>
                        <form className="user">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control form-control-user"
                              value={this.state.username}
                              onChange={(e) => {
                                this.setState({ username: e.target.value });
                              }}
                              placeholder="Email"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control form-control-user"
                              value={this.state.password}
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                              placeholder="Senha"
                            />
                          </div>
                          {this.state.requesting ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon icon={faCircleNotch} size={"2x"} spin color={"#4e73df"} />
                            </div>
                          ) : (
                            <button onClick={this.authenticate} className="btn btn-primary btn-user btn-block">
                              Login
                            </button>
                          )}
                          {this.state.serverError ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="text-danger mt-2">Erro ao se comunicar com servidor</span>
                            </div>
                          ) : this.state.credentialError ? (
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="text-danger mt-2">Credenciais inválidas</span>
                            </div>
                          ) : null}
                          <hr />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
