import {
  GET_ORDERS,
  NO_MORE_ORDERS,
  GET_PAST_STATEMENTS,
  NO_MORE_PAST_STATEMENTS,
  GET_FUTURE_STATEMENTS,
  NO_MORE_FUTURE_STATEMENTS,
  SET_FINGERPRINT_DATA,
} from "./actionTypes";
import Api from "../../Services/Api";
import ReactTooltip from "react-tooltip";
import { toastError } from "../../Util/funcions";

export const getPastStatements = (params = { offset: 0 }) => {
  return async (dispatch) => {
    let request = await Api.get("/statement/past", { params });
    let pastStatements = request.data.pastStatements;
    if (!pastStatements) dispatch({ type: GET_PAST_STATEMENTS, pastStatements: null, offset: params.offset });
    if (pastStatements.length < 50) dispatch({ type: NO_MORE_PAST_STATEMENTS });
    dispatch({ type: GET_PAST_STATEMENTS, pastStatements: request.data.pastStatements, offset: params.offset });
    ReactTooltip.rebuild();
  };
};

export const getFutureStatements = (params = { offset: 0 }) => {
  return async (dispatch) => {
    let request = await Api.get("/statement/future", { params });
    let futureStatements = request.data.futureStatements;
    if (!futureStatements) dispatch({ type: GET_FUTURE_STATEMENTS, futureStatements: null, offset: params.offset });
    if (futureStatements.length < 50) dispatch({ type: NO_MORE_FUTURE_STATEMENTS });
    dispatch({ type: GET_FUTURE_STATEMENTS, futureStatements: request.data.futureStatements, offset: params.offset });
    ReactTooltip.rebuild();
  };
};

export const getOrders = (params = { offset: 0 }) => {
  return async (dispatch) => {
    let request = await Api.get("/order", { params });
    let orders = request.data.orders;
    if (!orders) dispatch({ type: GET_ORDERS, orders: null, offset: params.offset });
    if (orders.length < 50) dispatch({ type: NO_MORE_ORDERS });
    dispatch({ type: GET_ORDERS, orders: request.data.orders, offset: params.offset });
    ReactTooltip.rebuild();
  };
};

export const searchOrders = (term) => {
  return async (dispatch) => {
    try {
      let request = await Api.get("/order/search/" + term);
      let orders = request.data.orders;
      if (!orders) dispatch({ type: GET_ORDERS, orders: null, offset: 0 });
      dispatch({ type: GET_ORDERS, orders, offset: 0 });
      ReactTooltip.rebuild();
    } catch (error) {
      toastError(error);
    }
  };
};

export const setFingerprintData = (visitorId, ip, latitude, longitude) => {
  return async (dispatch) => {
    dispatch({ type: SET_FINGERPRINT_DATA, visitorId, ip, latitude, longitude });
  };
};
