import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Api from "../../Services/Api";
import { toastError, parseMoney, getInt } from "../../Util/funcions";
import moment from "moment";
import OrderDetailsModal from "../../Components/OrderDetailsModal";
import DateRangePicker from "../../Components/DateRangePicker";
import { Collapse } from "react-collapse";
import { Dinero } from "../../Util/dinero";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrders, searchOrders, getFutureStatements } from "../../Redux/Actions";

import "./index.css";

class Statements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      metrics: {
        balance: 0,
        futureBalance: 0,
      },
      searchTerm: "",
      typing: false,
      typingTimeout: 0,
      dateRangeClick: 0,
      selectedOrder: null,
      filtered: false,
      loadingMore: false,
      maxReached: false,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      filterOpen: false,
    };
  }

  componentDidMount() {
    this.getMetrics();
    const { getFutureStatements } = this.props;
    getFutureStatements({ limit: 50, startDate: this.state.startDate, endDate: this.state.endDate });
  }

  getMetrics = async () => {
    try {
      let request = await Api.get("store/metrics");
      this.setState({ metrics: { balance: request.data.balance, futureBalance: request.data.futureBalance } });
    } catch (error) {
      toastError(error);
    }
  };

  handleOpenOrderDetails = (order) => {
    this.setState({ selectedOrder: order, isOrderDetailsModalOpen: true });
  };

  handleOpenOrderDetailsDiv = (order) => {
    if (window.innerWidth <= 768) this.setState({ selectedOrder: order, isOrderDetailsModalOpen: true });
  };

  handleLoadMore = async () => {
    const { getFutureStatements, offset } = this.props;
    getFutureStatements({
      offset: offset + 50,
      startDate: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : null,
      endDate: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : null,
    });
  };

  handleDateRangeSelect = (ranges) => {
    const { getFutureStatements } = this.props;
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate, dateRangeClick: this.state.dateRangeClick + 1 }, () => {
      if (this.state.dateRangeClick === 2) {
        this.setState({ dateRangeClick: 0, filtered: true });
        getFutureStatements({ offset: 0, startDate: moment(this.state.startDate).format("YYYY-MM-DD"), endDate: moment(this.state.endDate).format("YYYY-MM-DD") });
      }
    });
  };

  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  render() {
    const { requesting, noMoreStatements, statements } = this.props;
    const userType = localStorage.getItem("user_type");
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: "selection",
    };
    return (
      <>
        <div className="mx-2 mx-md-4">
          <div className="row">
            <div className="col-md-6">
              <div className="card border-left-success h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col-11">
                      <div className="text-s mb-1">SALDO DISPONÍVEL</div>
                      <div className="h5 mb-0 font-weight-bold">R$ {parseMoney(this.state.metrics.balance)}</div>
                    </div>
                    <div className="col-1">
                      <FontAwesomeIcon icon={faDollarSign} size={"2x"} className="text-gray-300 mr-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <div className="card border-left-primary h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col-11">
                      <div className="text-s mb-1">SALDO A RECEBER</div>
                      <div className="h5 mb-0 font-weight-bold">R$ {parseMoney(this.state.metrics.futureBalance)}</div>
                    </div>
                    <div className="col-1">
                      <FontAwesomeIcon icon={faDollarSign} size={"2x"} className="text-gray-300 mr-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-auto mt-4 align-items-center d-flex flex-row justify-content-between justify-content-md-start px-4">
              <h3>Lançamentos futuros</h3>
              <button className={`btn-filter ml-3 ${this.state.filterOpen ? "active" : ""}`} onClick={this.toggleFilter}>
                Filtros
              </button>
            </div>

            <div className="col-12 col-md-auto mt-1 mt-md-4 d-flex flex-row align-items-center justify-content-center">
              {!this.state.startDate || !this.state.endDate ? (
                <span>Mostrando tudo</span>
              ) : (
                <span>
                  Mostrando de {moment(this.state.startDate).format("DD/MM/YYYY")} até {moment(this.state.endDate).format("DD/MM/YYYY")}
                </span>
              )}
            </div>

            <div className="col-12 text-center text-md-left mt-2 mb-3">
              <Collapse isOpened={this.state.filterOpen}>
                <DateRangePicker showPreview={false} showDateDisplay={false} ranges={[selectionRange]} onChange={this.handleDateRangeSelect} />
              </Collapse>
            </div>

            <div className="col-12 mt-1">
              <div className="card mb-4">
                <div className="px-3 mt-3">
                  <div className="d-flex justify-content-between align-items-center hide-on-mobile">
                    <div className="col">
                      <span className="font-weight-bold text-md">Data</span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold text-md">Descrição</span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold text-md">Valor</span>
                    </div>
                  </div>
                  <hr className="hide-on-mobile" />

                  {requesting ? (
                    <div className="text-center p-2 mb-3">BUSCANDO</div>
                  ) : statements.length === 0 ? (
                    <div className="text-center p-2 mb-3">Sem resultados</div>
                  ) : (
                    <>
                      <div>
                        <div className="d-flex justify-content-between align-items-center order-container">
                          <div className="col">
                            <span> - </span>
                          </div>
                          <div className="col text-right">
                            <span className="font-weight-bold"> Total: </span>
                          </div>
                          <div className="col">
                            <span className="font-weight-bold">
                              {Dinero({
                                amount: getInt(
                                  statements.reduce(
                                    (prev, curr) =>
                                      Dinero({ amount: getInt(prev) })
                                        .add(Dinero({ amount: getInt(userType === "ADMIN" ? curr.platform_value : curr.affiliate_value) }))
                                        .toUnit(),
                                    0
                                  )
                                ),
                              }).toFormat()}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </div>
                      {statements.map((statement) => (
                        <div key={statement.id}>
                          <div
                            className="d-flex justify-content-between align-items-center order-container"
                            onClick={() => {
                              this.handleOpenOrderDetailsDiv(statement.Order);
                            }}
                          >
                            <div className="col">
                              <span>{moment(statement.settle_date).format("DD/MM/YYYY")}</span>
                            </div>
                            <div className="col">
                              <button
                                className="btn-neutral link-hover"
                                data-tip="Clique para ver detalhes"
                                onClick={() => {
                                  this.handleOpenOrderDetails(statement.Order);
                                }}
                              >
                                <span>
                                  Pagamento <b>{statement.Order.own_code}</b> - Parcela {statement.installment_number} de {statement.Order.installment_count}
                                </span>
                              </button>
                            </div>
                            <div className="col">
                              <span>R$ {parseMoney(userType === "ADMIN" ? statement.platform_value : statement.affiliate_value)}</span>
                            </div>
                          </div>
                          <hr />
                        </div>
                      ))}
                    </>
                  )}
                  <div className="col-12 text-center mb-3">
                    {noMoreStatements || this.state.filtered ? (
                      <span>Mostrando todos os resultados</span>
                    ) : (
                      <button className="btn-neutral btn-load-more" onClick={this.handleLoadMore}>
                        Mostrar mais
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrderDetailsModal
          order={this.state.selectedOrder}
          isOpen={this.state.isOrderDetailsModalOpen}
          onRequestClose={() => {
            this.setState({ isOrderDetailsModalOpen: false, selectedOrder: null });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  requesting: store.main.requesting,
  noMoreStatements: store.statements.noMoreFutureStatements,
  offset: store.statements.futureStatementsOffset,
  statements: store.statements.futureStatements,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getOrders, searchOrders, getFutureStatements }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Statements);
