import React, { Component } from "react";
import Axios from "axios";
import MaskedInput from "react-text-mask";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Api from "../../../Services/Api";
import { toastError } from "../../../Util/funcions";
import moment from "moment";

export default class RegisterCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestingAddress: false,
      requestingRegister: false,
      cpfSelected: true,
      cnpjSelected: false,
    };
    this.streetNumberRef = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var data = {};
    let form = new FormData(document.getElementById("customer_form"));
    var elements = document.getElementById("customer_form").elements;
    for (var i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (element.type === "text" || ((element.type === "email") === element.type) === "number") {
        if (element.value.trim() === "".trim()) {
          element.focus();
          return toastError(new Error(element.alt + " obrigatório(a)"));
        }
      }
    }
    form.forEach((value, key) => {
      if (value !== "") data[key] = value;
    });
    console.log(data, "customerData handleSubmit data");
    this.props.next(data);
  };

  componentDidMount() {
    this.getCustomerData(true);
  }

  getCustomerData = async (isStart) => {
    try {
      let customerRequest = await Api.get("/customer/by_order_id/" + this.props.order.id);
      let customer = customerRequest.data.customer;
      console.log(customer, "customer");
      if (isStart) {
        if (customer.cnpj) {
          return this.setState({ cnpjSelected: true, cpfSelected: false }, () => {
            this.fillForm(customer);
          });
        } else {
          return this.setState({ cnpjSelected: false, cpfSelected: true }, () => {
            this.fillForm(customer);
          });
        }
      } else {
        return this.fillForm(customer);
      }
    } catch (error) {
      console.log(error.response);
      toastError(error);
    }
  };

  fillForm = (customer) => {
    let form = document.forms["customer_form"];
    form.elements["name"].value = customer.name;
    form.elements["cpf"].value = customer.cpf;
    form.elements["email"].value = customer.email;
    form.elements["phone_ddd"].value = customer.phone_ddd;
    form.elements["phone_number"].value = customer.phone_number;
    form.elements["street"].value = customer.street;
    form.elements["street_number"].value = customer.street_number;
    form.elements["city"].value = customer.city;
    form.elements["state"].value = customer.state;
    form.elements["neighborhood"].value = customer.neighborhood;
    form.elements["postal_code"].value = customer.postal_code;
    form.elements["customer_birthdate"].value =
      customer.birthdate && moment(customer.birthdate).add(3, "hours").isValid()
        ? moment(customer.birthdate).add(3, "hours").format("DD/MM/YYYY")
        : null;

    if (this.state.cnpjSelected) {
      if (customer.cnpj) form.elements["cnpj"].value = customer.cnpj;
      if (customer.company_name) form.elements["company_name"].value = customer.company_name;
      if (customer.trade_name) form.elements["trade_name"].value = customer.trade_name;
    }
  };

  searchAddress = async (e) => {
    this.setState({ requestingAddress: true });
    let cep = e.target.value.replace(/^\D+/g, "");
    try {
      let addressRequest = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (addressRequest.data.erro) {
        this.setState({ requestingAddress: false });
        return alert("Erro ao buscar endereço com o CEP digitado");
      }
      let form = document.forms["customer_form"];
      let address = addressRequest.data;
      form.elements["street"].value = address.logradouro;
      form.elements["city"].value = address.localidade;
      form.elements["state"].value = address.uf;
      form.elements["neighborhood"].value = address.bairro;
      this.streetNumberRef.current.focus();
      this.setState({ requestingAddress: false });
    } catch (error) {
      alert("Erro ao buscar endereço com o CEP digitado");
      this.setState({ requestingAddress: false });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="row text-left px-1" id="customer_form">
        <div className="col-12 mt-2">
          <h4 className="text-primary">Tipo de cadastro</h4>
          <span>Informe se você é pessoa física ou pessoa jurídica</span>
          <span className="text-danger ml-1">*</span>
        </div>
        <div className="col-6 col-md-auto mt-2">
          <button
            className={`btn btn-${this.state.cpfSelected ? "primary" : "secondary"} btn-icon-split btn-block d-flex justify-content-start`}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ cpfSelected: true, cnpjSelected: false });
              this.getCustomerData();
            }}
          >
            <span className="icon text-white d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={this.state.cpfSelected ? faDotCircle : faCircle} />
            </span>
            <span className="text w-100">Pessoa{window.innerWidth <= 768 ? <br /> : " "}Física</span>
          </button>
        </div>
        <div className="col-6 col-md-auto mt-2">
          <button
            className={`btn btn-${this.state.cnpjSelected ? "primary" : "secondary"} btn-icon-split btn-block d-flex justify-content-start `}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ cpfSelected: false, cnpjSelected: true });
              this.getCustomerData();
            }}
          >
            <span className="icon text-white d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={this.state.cnpjSelected ? faDotCircle : faCircle} />
            </span>
            <span className="text w-100">Pessoa{window.innerWidth <= 768 ? <br /> : " "}Jurídica</span>
          </button>
        </div>
        {this.state.cpfSelected || this.state.cnpjSelected ? (
          <>
            <div className="col-12 mt-4">
              <h4 className="text-primary">Dados pessoais</h4>
            </div>
            <div className="form-group col-md-6">
              <label>Nome do responsável</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="name" className="form-control" alt="Nome" />
            </div>
            <div className="form-group col-md-6">
              <label>CPF</label>
              <span className="text-danger ml-1">*</span>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                name="cpf"
                className="form-control"
                alt="CPF"
              />
            </div>
            <div className="form-group col-md-6">
              <label>Data de nascimento</label>
              <span className="text-danger ml-1">*</span>
              <MaskedInput mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} name="customer_birthdate" className="form-control" />
            </div>
            <div className="form-group col-md-6">
              <label>Email</label>
              <span className="text-danger ml-1">*</span>
              <input type="email" name="email" className="form-control" alt="E-mail" />
            </div>
            <div className="form-group col-md-2">
              <label>DDD</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="phone_ddd" maxLength={2} className="form-control" alt="DDD" minLength={2} />
            </div>
            <div className="form-group col-md-4">
              <label>Número</label>
              <span className="text-danger ml-1">*</span>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                name="phone_number"
                className="form-control"
                alt="Telefone"
              />
            </div>

            {!this.state.cpfSelected ? (
              <>
                <div className="col-12 py-2">
                  <div className="separator" />
                </div>
                <div className="col-12">
                  <h4 className="text-primary">Dados da empresa</h4>
                </div>
                <div className="form-group col-md-6">
                  <label>Nome fantasia</label>
                  <span className="text-danger ml-1">*</span>
                  <input type="text" name="trade_name" alt="Nome fantasia" className="form-control" />
                </div>
                <div className="form-group col-md-6">
                  <label>Razão social</label>
                  <span className="text-danger ml-1">*</span>
                  <input type="text" name="company_name" alt="Razão social" className="form-control" />
                </div>
                <div className="form-group col-md-6">
                  <label>CNPJ</label>
                  <span className="text-danger ml-1">*</span>
                  <MaskedInput
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                    name="cnpj"
                    className="form-control"
                    alt="CNPJ"
                  />
                </div>
              </>
            ) : null}
            <div className="col-12 py-2">
              <div className="separator" />
            </div>
            <div className="col-12 mt-2">
              <h4 className="text-primary">Endereço</h4>
            </div>
            <div className="form-group col-md-3">
              <label>CEP</label>
              <span className="text-danger ml-1">*</span>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                name="postal_code"
                className="form-control"
                alt="Endereço(CEP)"
                onBlur={this.searchAddress}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Rua</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="street" className="form-control" disabled={this.state.requestingAddress} alt="Endereço(Rua)" />
            </div>
            <div className="form-group col-md-3">
              <label>Número</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="street_number" className="form-control" ref={this.streetNumberRef} alt="Endereço(Número)" />
            </div>
            <div className="form-group col-md-5">
              <label>Cidade</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="city" className="form-control" disabled={this.state.requestingAddress} alt="Endereço(Cidade)" />
            </div>
            <div className="form-group col-md-5">
              <label>Bairro</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="neighborhood" className="form-control" disabled={this.state.requestingAddress} alt="Endereço(Bairro)" />
            </div>
            <div className="form-group col-md-2">
              <label>Estado</label>
              <span className="text-danger ml-1">*</span>
              <select name="state" className="form-control" defaultValue="" alt="Endereço(Estado)">
                <option value="" disabled>
                  UF
                </option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </select>
            </div>
            <div className="col-12 py-2 mb-2">
              <div className="separator" />
            </div>
            {this.state.requestingRegister ? (
              <span>Cadastrando...</span>
            ) : (
              <>
                <div className="col-12 text-center mr-1 mt-3 mt-md-0">
                  <button type="submit" className={`btn btn-primary btn-icon-split btn-md`}>
                    <span className="text">Avançar</span>
                    <span className="icon text-white">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </button>
                </div>
              </>
            )}
          </>
        ) : null}
      </form>
    );
  }
}
