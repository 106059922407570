import { GET_PAST_STATEMENTS, REQUESTING_OPERATION, NO_MORE_PAST_STATEMENTS, GET_FUTURE_STATEMENTS, NO_MORE_FUTURE_STATEMENTS } from "../Actions/actionTypes";

const initialState = {
  pastStatements: [],
  futureStatements: [],
  requesting: false,
  noMorePastStatements: false,
  noMoreFutureStatements: false,
  pastStatementsOffset: 0,
  futureStatementsOffset: 0,
};

export const getStatements = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAST_STATEMENTS:
      if (!action.pastStatements) return initialState;
      let pastStatements = action.pastStatements;
      return {
        ...state,
        pastStatements: action.offset !== 0 ? [...state.pastStatements, ...pastStatements] : action.pastStatements,
        requesting: false,
        noMorePastStatements: pastStatements.length < 50,
        pastStatementsOffset: action.offset,
      };
    case GET_FUTURE_STATEMENTS:
      if (!action.futureStatements) return initialState;
      let futureStatements = action.futureStatements;
      return {
        ...state,
        futureStatements: action.offset !== 0 ? [...state.futureStatements, ...futureStatements] : action.futureStatements,
        requesting: false,
        noMorePastStatements: futureStatements.length < 50,
        futureStatementsOffset: action.offset,
      };
    case REQUESTING_OPERATION:
      return { ...state, requesting: true };
    case NO_MORE_PAST_STATEMENTS:
      return { ...state, noMorePastStatements: true };
    case NO_MORE_FUTURE_STATEMENTS:
      return { ...state, noMoreFutureStatements: true };
    default:
      return state;
  }
};
