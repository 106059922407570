import {
  GET_PAST_STATEMENTS,
  REQUESTING_OPERATION,
  NO_MORE_PAST_STATEMENTS,
  GET_FUTURE_STATEMENTS,
  NO_MORE_FUTURE_STATEMENTS,
  GET_VISITOR_ID,
  SET_FINGERPRINT_DATA,
} from "../Actions/actionTypes";

const initialState = {
  visitorId: null,
  geolocation: {
    latitude: null,
    longitude: null,
  },
  ip: null,
};

export const getFingerprint = (state = initialState, action) => {
  switch (action.type) {
    case SET_FINGERPRINT_DATA:
      return {
        ...state,
        visitorId: action.visitorId ?? state.visitorId,
        ip: action.ip ?? state.ip,
        geolocation: { latitude: action.latitude ?? state.geolocation.latitude, longitude: action.longitude ?? state.geolocation.longitude },
      };
    default:
      return state;
  }
};
