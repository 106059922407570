import React, { useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { toastError } from "../../Util/funcions";
import { getOrders } from "../../Redux/Actions";
import Api from "../../Services/Api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderWidth: 0.5,
    boxShadow: "0 0 5px",
    borderRadius: 10,
  },
};

ReactModal.setAppElement("#root");

export default function RastreioModal(props) {
    const [tracking_code, setTracking_code] = useState('');    
    
    const handleRequestClose = () => {
        props.onRequestClose();
    }

    const register = async () => {
        try {            
            await Api.put(`/order/${props.trackingSelectedOrder}`, {tracking_code});
            handleRequestClose();
            document.location.reload(true);
            getOrders();
            
        } catch (error) {
            toastError(error);
        }
    }

    return (
        <div>
            <ReactModal isOpen={props.isOpen} style={customStyles} onRequestClose={handleRequestClose}>
                <button onClick={props.onRequestClose} className="ml-auto mr-2 float-right" style={{ border: "none" }}>
                    <FontAwesomeIcon icon={faTimes} size={"2x"} color={"grey"} />
                </button>
                <h3 className="ml-3 pb-2">Rastreio</h3>
                <p className="ml-3">Adicione um código de rastreio.</p>
                <hr />
                <input className="p-3 form-control" type="text" id="inputTrackingCode" value={tracking_code} onChange={(e) => {setTracking_code(e.target.value)}}/>
                <button className="btn btn-block btn-primary mt-3" onClick={register}>Salvar</button>

            </ReactModal>
        </div>
    );
}