import { toast } from "react-toastify";
import VMasker from "vanilla-masker";

export function toastError(error) {
  if (error.response && error.response.data) {
    if (error.response.data.userMessage) return toast.error(error.response.data.userMessage);
    return toast.error(error.response.data.message);
  } else {
    return toast.error(error.message);
  }
}

export function parseMoney(value = 0) {
  return VMasker.toMoney((parseFloat(value) || 0).toFixed(2));
}

export function textoBandeira(cardnumber) {
  cardnumber = cardnumber.replace(/[^\d]+/g, "");

  var cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    amex: /^3[47][0-9]{13}/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  };

  for (var flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return false;
}

export function idBandeira(cardnumber) {
  cardnumber = cardnumber.replace(/[^\d]+/g, "");

  var cards = {
    3: /^4[0-9]{12}(?:[0-9]{3})/,
    4: /^5[1-5][0-9]{14}/,
    5: /^3[47][0-9]{13}/,
    20: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    16: /^401178|^401179|^431274|^438935|^451416|^457393|^457631|^457632|^504175|^627780|^636297|^636368|^636369|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
    18: /^(5078\d{2})(\d{2})(\d{11})$/,
  };

  for (var flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return false;
}

export function getInt(float) {
  return Math.round((float ?? 0) * Math.pow(10, 2));
}
