import React, { Component } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faExternalLinkAlt, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import "./index.css";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: "pagamentos",
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  logout = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  getCurrentPage = () => {
    if (window.location.pathname.includes("lancamentos")) return this.setState({ currentPage: "lancamentos" });
    if (window.location.pathname.includes("pagamentos")) return this.setState({ currentPage: "pagamentos" });
  };

  render() {
    const userType = localStorage.getItem("user_type");
    return (
      <ul className="navbar-nav sidebar accordion toggled" id="accordionSidebar">
        <div className="sidebar-brand-text text-white text-center my-3 mx-3">
          <img src={require("../../Assets/Images/logo.png")} className="img-fluid" alt="Emepag logo" />
        </div>

        <hr className="sidebar-divider my-0" />

        <li className={`nav-item ${this.state.currentPage === "pagamentos" ? "active" : ""} sidebarToggleBtn`}>
          <Link
            to={"/app/pagamentos"}
            className="nav-link"
            onClick={() => {
              this.setState({ currentPage: "pagamentos" });
            }}
          >
            <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
            <span>Pagamentos</span>
          </Link>
        </li>

        {
          /* Lançamentos */
          userType === "STORE_SELLER"  ? null :

            <li className={`nav-item ${this.state.currentPage === "lancamentos" ? "active" : ""} sidebarToggleBtn`}>
              <Link
                to={"/app/lancamentos"}
                className="nav-link"
                onClick={() => {
                  this.setState({ currentPage: "lancamentos" });
                }}
              >
                <FontAwesomeIcon icon={faDollarSign} className="mr-2" />
                <span>Lançamentos</span>
              </Link>
            </li>
        }

        <li className={`nav-item ${this.state.currentPage === "clientes" ? "active" : ""} sidebarToggleBtn`}>
          <button to={"/app/clientes"} className="nav-link btn-neutral" onClick={this.logout}>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
            <span>Sair</span>
          </button>
        </li>
      </ul>
    );
  }
}
