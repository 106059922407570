import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons"; 

const BalanceCard = (props) => {
    return(
        <>
            <div className="col-md-6">
                <div className="card border-left-success h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col-11">
                                <div className="text-s mb-1">SALDO DISPONÍVEL</div>
                                <div className="h5 mb-0 font-weight-bold">R$ {props.Balance} </div>
                            </div>
                            <div className="col-1">
                                <FontAwesomeIcon icon={faDollarSign} size={"2x"} className="text-gray-300 mr-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
                <div className="card border-left-primary h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col-11">
                                <div className="text-s mb-1">SALDO A RECEBER</div>
                                <div className="h5 mb-0 font-weight-bold">R$ {props.FutureBalance}</div>
                            </div>
                            <div className="col-1">
                                <FontAwesomeIcon icon={faDollarSign} size={"2x"} className="text-gray-300 mr-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BalanceCard;