import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkModal from "../LinkModal";

export default class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLinkModalOpen: false,
    };
  }

  openLinkModal = () => {
    this.setState({ isLinkModalOpen: true });
  };

  closeLinkModal = () => {
    this.setState({ isLinkModalOpen: false });
  };

  logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  render() {
    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-1 static-top">
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <FontAwesomeIcon icon={faBars} />
        </button>
        <button onClick={this.openLinkModal} className="btn btn-primary btn-icon-split">
          <span className="icon text-white-25">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="text">
            Criar link <span className="hide-on-mobile">de pagamento</span>
          </span>
        </button>
        <LinkModal isOpen={this.state.isLinkModalOpen} onRequestClose={this.closeLinkModal} />
      </nav>
    );
  }
}
