import jQuery from "jquery";
(function ($) {
  // Toggle the side navigation
  $("#sidebarToggle, #sidebarToggleTop, .sidebarToggleBtn").on("click", function (e) {
    if (this.className.includes("sidebarToggleBtn") && window.innerWidth > 768) return;
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
    if ($(".sidebar").hasClass("toggled")) {
      $(".sidebar-on").animate(
        {
          left: "-=220",
        },
        300
      );
      $(".sidebar").animate(
        {
          width: "-=220",
        },
        300
      );
      setTimeout(() => {
        $("#content-wrapper").removeClass("sidebar-on");
      }, 300);
    } else {
      $("#content-wrapper").addClass("sidebar-on");
      $(".sidebar-on").animate(
        {
          left: "+=220",
        },
        300
      );
      $(".sidebar").animate(
        {
          width: "+=220",
        },
        300
      );
    }
  });

  // Close any open menu accordions when window is resized below 768px
  $(window).resize(function () {
    if ($(window).width() < 768) {
      $(".sidebar .collapse").collapse("hide");
    }
  });
})(jQuery); // End of use strict
