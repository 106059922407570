import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./Theme/sb-admin-2.css";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./global.css";

ReactDOM.render(<App />, document.getElementById("root"));
require("./Theme/sb-admin-2.js");
