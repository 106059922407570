import React, { Component } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import SearchCustomer from "../SearchCustomer";
import "./index.css";
import { Carousel } from "react-responsive-carousel";
import RegisterCustomer from "../RegisterCustomer";
import CreateLink from "../CreateLink";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    borderWidth: 0.5,
    boxShadow: "0 0 20px",
    borderRadius: 10,
    minHeight: "90%",
  },
};
ReactModal.setAppElement("#root");

export default class LinkModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselItem: 0,
      modalTitle: "Criar link de pagamento",
      selectedCustomer: null,
      selectedCustomerName: "",
    };
  }

  handleRequestClose = () => {
    this.setState({ selectedCustomer: null, selectedCustomerName: "", carouselItem: 0, modalTitle: "Criar link de pagamento" });
    this.props.onRequestClose();
  };

  changeCarouselItem = (item, modalTitle, customerName) => {
    this.setState({ carouselItem: item, modalTitle, selectedCustomerName: customerName });
  };

  handleSelectCustomer = (customerId, customerName) => {
    this.setState({ selectedCustomerName: customerName, selectedCustomer: customerId, carouselItem: 2, modalTitle: "Criar link de pagamento" });
  };

  handleRegisterCustomer = (customerId, customerName, saveAndCreateLink) => {
    if (saveAndCreateLink) {
      this.handleSelectCustomer(customerId, customerName);
    } else {
      this.handleRequestClose();
    }
  };

  render() {
    return (
      <ReactModal isOpen={this.props.isOpen} onRequestClose={this.handleRequestClose} style={customStyles}>
        <div className="link_modal-header d-flex justify-content-between">
          <h3>{this.state.modalTitle}</h3>
          <button className="btn-neutral" onClick={this.handleRequestClose}>
            <FontAwesomeIcon icon={faTimes} size={"2x"} color={"grey"} />
          </button>
        </div>
        <hr />
        <div>
          <Carousel
            selectedItem={this.state.carouselItem}
            showStatus={false}
            showIndicators={false}
            swipeable={false}
            showThumbs={false}
            showArrows={false}
            dynamicHeight={true}
          >
            <div style={{ minHeight: 500 }}>
              <SearchCustomer
                onSelectCustomer={this.handleSelectCustomer}
                goToRegisterCustomer={(customerName) => {
                  this.changeCarouselItem(1, "Cadastrar Cliente", customerName);
                }}
              />
            </div>
            <div>
              <RegisterCustomer customerName={this.state.selectedCustomerName} onRegisterCustomer={this.handleRegisterCustomer} />
            </div>
            <div>
              <CreateLink customerId={this.state.selectedCustomer} customerName={this.state.selectedCustomerName} />
            </div>
          </Carousel>
        </div>
      </ReactModal>
    );
  }
}
