import React, { Component } from "react";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { parseMoney, toastError } from "../../../Util/funcions";
import Lottie from "react-lottie";
import * as loadginAnimation from "../../../Assets/Animations/loading.json";
import Api from "../../../Services/Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "./index.css";
import MaskedInput from "react-text-mask";
import Fingerprint from "fingerprintjs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFingerprint } from "../../../Redux/Reducers/system";
const MoipCreditCard = require("moip-sdk-js").MoipCreditCard;

const yapayCreditCardTable = {
  visa: 3,
  master: 4,
  american_express: 5,
  discover: 15,
  elo: 16,
  aura: 18,
  jcb: 19,
  hiper: 20,
  hiper_itau: 25,
};

class PaymentData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditCardSelect: false,
      boletoSelected: false,
      pixSelected: false,
      processingCreditCardPayment: false,
      generatingBoleto: false,
      generatingPix: false,
      idBandeira: null,
      cpfSelected: true,
      cnpjSelected: false,
      boletoReady: false,
      pixReady: false,
      order: this.props.order,
      selectedCreditCardBrand: null,
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      window.yapay.FingerPrint();
    } else {
      window.yapay.FingerPrint({ env: "sandbox" });
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    var data = {};
    let form = new FormData(document.getElementById("payment_form"));
    form.forEach((value, key) => {
      if (value !== "") data[key] = value;
    });
    let finalData = {
      ...this.props.customerData,
      ...data,
      order_code: this.state.order.own_code,
    };
    var fingerprint = new Fingerprint({ canvas: true }).get();
    finalData.fingerprint = fingerprint;

    try {
      if (!data.credit_card_number && this.state.creditCardSelect) return toast.error("Número do cartão obrigatório");
      if (this.state.creditCardSelect) {
        if (!this.state.selectedCreditCardBrand) return toast.error("Selecione a bandeira do seu cartão");
        try {
          finalData.payment_type_id = this.state.selectedCreditCardBrand;
          this.setState({ processingCreditCardPayment: true });
          await Api.post("/order/pay/credit_card", finalData);
          setTimeout(() => {
            this.setState({ processingCreditCardPayment: false });
            window.location.href = window.location.href.replace(`?force=true`, "");
          }, 1000);
        } catch (error) {
          toastError(error);
          this.setState({ processingCreditCardPayment: false });
        }
      } else if (this.state.boletoSelected) {
        this.setState({ generatingBoleto: true });
        try {
          let paymentRequest = await Api.post("/order/pay/boleto", finalData);
          this.setState({ boletoReady: true, generatingBoleto: false, order: paymentRequest.data.order });
        } catch (error) {
          this.setState({ generatingBoleto: false });
          toastError(error);
        }
      } else if (this.state.pixSelected) {
        this.setState({ generatingPix: true });
        try {
          let paymentRequest = await Api.post("/order/pay/pix", finalData);
          this.setState({ pixReady: true, generatingPix: false, order: paymentRequest.data.order });
        } catch (error) {
          this.setState({ generatingPix: false });
          toastError(error);
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  getInstallmentsList = () => {
    let options = [];
    for (let i = 1; i <= this.state.order.installment_count; i++) {
      options.push(
        <option value={i} key={i}>
          {i}x de R$ {parseMoney((this.state.order.value / i).toFixed(2))}
        </option>
      );
    }
    return options;
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="row text-left px-1" id="payment_form" data-yapay="payment-form">
        <div className="col-12 mt-2">
          <h4 className="text-primary">Tipo de pagamento</h4>
        </div>
        {this.state.order.Store?.enable_credit_card && this.state.order.credit_card_enabled && (
          <div className="col col-md-auto mt-2">
            <button
              className={`btn btn-${this.state.creditCardSelect ? "primary" : "secondary"} btn-icon-split btn-block d-flex justify-content-start`}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ creditCardSelect: true, boletoSelected: false, pixSelected: false }, () => {
                  if (process.env.NODE_ENV === "production") {
                    window.yapay.FingerPrint();
                  } else {
                    window.yapay.FingerPrint({ env: "sandbox" });
                  }
                });
              }}
            >
              <span className="icon text-white d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={this.state.creditCardSelect ? faDotCircle : faCircle} />
              </span>
              <span className="text w-100">Cartão de{window.innerWidth <= 768 ? <br /> : " "}Crédito</span>
            </button>
          </div>
        )}
        {this.state.order.Store?.enable_boleto && this.state.order.boleto_enabled && (
          <div className="col col-md-auto mt-2">
            <button
              className={`btn btn-${this.state.boletoSelected ? "primary" : "secondary"} btn-icon-split btn-block d-flex justify-content-start`}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ creditCardSelect: false, boletoSelected: true, pixSelected: false });
              }}
            >
              <span className="icon text-white d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={this.state.boletoSelected ? faDotCircle : faCircle} />
              </span>
              <span className="text w-100 btn-checkout-boleto-text">Boleto</span>
            </button>
          </div>
        )}
        {this.state.order.Store?.enable_pix && this.state.order.pix_enabled && (
          <div className="col col-md-auto mt-2">
            <button
              className={`btn btn-${this.state.pixSelected ? "primary" : "secondary"} btn-icon-split btn-block d-flex justify-content-start`}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ creditCardSelect: false, boletoSelected: false, pixSelected: true });
              }}
            >
              <span className="icon text-white d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={this.state.pixSelected ? faDotCircle : faCircle} />
              </span>
              <span className="text w-100 btn-checkout-boleto-text">Pix</span>
            </button>
          </div>
        )}

        {this.state.creditCardSelect ? (
          <>
            <div className="col-12 mt-4">
              <h4 className="text-primary">Dados do cartão</h4>
            </div>

            <div className="form-group col-12">
              <label className="d-block">Bandeira do cartão</label>
              <div className="d-flex flex-row">
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.visa ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/1-visa.png")})`,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.visa });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.master ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/2-mastercard.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.master });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.hiper ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/3-hipercard.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.hiper });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.american_express ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/4-american-express.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.american_express });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.elo ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/5-elo.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.elo });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.aura ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/7-aura.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.aura });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.discover ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/8-discover.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.discover });
                  }}
                />
                <div
                  style={{
                    ...styles.cardFlag,
                    opacity: this.state.selectedCreditCardBrand === yapayCreditCardTable.jcb ? 1 : 0.3,
                    backgroundImage: `url(${require("../../../Assets/Images/card_icons/9-jcb.png")})`,
                    marginLeft: 5,
                  }}
                  onClick={() => {
                    this.setState({ selectedCreditCardBrand: yapayCreditCardTable.jcb });
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>Nome do titular</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="card_name" className="form-control" placeholder="Igual ao que aparece no cartão" />
            </div>
            <div className="form-group col-md-6">
              <label>Número do cartão</label>
              <span className="text-danger ml-1">*</span>
              <InputMask mask="9999 9999 9999 9999" placeholder="0000 0000 0000 0000">
                {(inputProps) => <input {...inputProps} type="text" name="credit_card_number" className="form-control" />}
              </InputMask>
            </div>
            <div className="form-group col-md-6 row no-gutters">
              <div className="col-12">
                <label>Data de validade</label>
                <span className="text-danger ml-1">*</span>
              </div>
              <select className="form-control col-5" name="val_month">
                <option value="" disabled selected hidden>
                  Mês
                </option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select className="form-control col-5 ml-4" name="val_year">
                <option value="" disabled selected hidden>
                  Ano
                </option>
                <option value="2022">22</option>
                <option value="2023">23</option>
                <option value="2024">24</option>
                <option value="2025">25</option>
                <option value="2026">26</option>
                <option value="2027">27</option>
                <option value="2028">28</option>
                <option value="2029">29</option>
                <option value="2030">30</option>
                <option value="2031">31</option>
                <option value="2032">32</option>
                <option value="2033">33</option>
                <option value="2034">34</option>
                <option value="2035">35</option>
                <option value="2036">36</option>
                <option value="2037">37</option>
                <option value="2038">38</option>
                <option value="2039">39</option>
                <option value="2040">40</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Código de segurança</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="cvv" className="form-control w-50" placeholder="CVV" maxLength={4} />
            </div>
            <div className="form-group col-md-6 row no-gutters">
              <div className="col-12">
                <label>CPF</label>
                <span className="text-danger ml-1">*</span>
              </div>
              <div className="col-8">
                {this.state.cpfSelected ? (
                  <InputMask mask="999.999.999-99" placeholder="CPF do titular">
                    {(inputProps) => <input {...inputProps} type="text" name="card_cpf" className="form-control" />}
                  </InputMask>
                ) : (
                  <InputMask mask="99.999.999/9999-99" placeholder="CNPJ do titular">
                    {(inputProps) => <input {...inputProps} type="text" name="card_cnpj" className="form-control" />}
                  </InputMask>
                )}
              </div>
              <div className="col-2 d-flex align-items-center">
                <button
                  className={`btn btn-${this.state.cpfSelected ? "primary" : "secondary"} btn-sm ml-2`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ cpfSelected: true, cnpjSelected: false });
                  }}
                >
                  <span>CPF</span>
                </button>
              </div>
              <div className="col-2 d-flex align-items-center">
                <button
                  className={`btn btn-${this.state.cnpjSelected ? "primary" : "secondary"} btn-sm ml-2`}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ cpfSelected: false, cnpjSelected: true });
                  }}
                >
                  <span>CNPJ</span>
                </button>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label>Data de nascimento do titular</label>
              <span className="text-danger ml-1">*</span>
              <MaskedInput mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]} name="birthdate" className="form-control" />
            </div>
            <div className="form-group col-md-6">
              <label>Número de parcelas</label>
              <span className="text-danger ml-1">*</span>
              <select className="form-control" name="installment_count">
                <option value="" disabled selected hidden>
                  Selecione
                </option>
                {this.getInstallmentsList()}
              </select>
            </div>
            <div className="col-12 my-4">
              <div className="separator" />
            </div>

            {this.state.processingCreditCardPayment ? (
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <Lottie options={{ loop: true, autoplay: true, animationData: loadginAnimation.default }} height={100} width={100} />
                <h5>Processando seu pagamento. Aguarde...</h5>
              </div>
            ) : (
              <>
                <div className="col-12 text-center mr-1 mt-3 mt-md-2">
                  <button type="submit" className={`btn btn-primary btn-icon-split btn-md`}>
                    <span className="text">Efetuar pagamento</span>
                    <span className="icon text-white">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </button>
                </div>
              </>
            )}
          </>
        ) : this.state.boletoSelected ? (
          <div className="col-12 d-flex flex-column align-items-center justify-content-center mt-5">
            {this.state.boletoReady || this.state.order.boleto_url ? (
              <div className="text-center">
                <span className="d-block">Aqui está o seu boleto</span>
                <CopyToClipboard
                  text={this.state.order.boleto_code}
                  onCopy={() => {
                    toast.success("Código copiado");
                  }}
                >
                  <div className="checkout_boleto_code">
                    <span className="d-block mt-2">{this.state.order.boleto_code}</span>
                    <span className="d-block mb-2">Clique para copiar a linha digitavel</span>
                  </div>
                </CopyToClipboard>
                <a
                  href={this.state.order.boleto_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`btn btn-primary btn-icon-split btn-md mt-2`}
                >
                  <span className="text">Acessar boleto</span>
                  <span className="icon text-white">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </a>
              </div>
            ) : this.state.generatingBoleto ? (
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <Lottie options={{ loop: true, autoplay: true, animationData: loadginAnimation.default }} height={100} width={100} />
                <h5>Gerando seu boleto. Aguarde...</h5>
              </div>
            ) : (
              <button className={`btn btn-primary btn-icon-split btn-md`}>
                <span className="text">Gerar boleto</span>
                <span className="icon text-white">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>
            )}
          </div>
        ) : (
          <div className="col-12 d-flex flex-column align-items-center justify-content-center mt-5">
            {this.state.pixReady || this.state.order.pix_copia_cola ? (
              <div className="text-center">
                <span className="d-block mb-2">QR Code</span>
                <div>
                  <object type="image/svg+xml" data={this.state.order.pix_qr_code} width="300" height="300">
                    Seu navegador não suporta SVG.
                  </object>
                </div>
                <CopyToClipboard
                  text={this.state.order.pix_copia_cola}
                  onCopy={() => {
                    toast.success("Código copiado");
                  }}
                >
                  <div className="checkout_pix_copia_cola">
                    <span className="d-block mt-2">{this.state.order.pix_copia_cola}</span>
                    <span className="d-block mb-2 mt-3 font-weight-bold">Clique para copiar o pix copia e cola</span>
                  </div>
                </CopyToClipboard>
              </div>
            ) : this.state.generatingPix ? (
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <Lottie options={{ loop: true, autoplay: true, animationData: loadginAnimation.default }} height={100} width={100} />
                <h5>Gerando seus dados para PIX. Aguarde...</h5>
              </div>
            ) : (
              <button className={`btn btn-primary btn-icon-split btn-md`}>
                <span className="text">Gerar dados PIX</span>
                <span className="icon text-white">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>
            )}
          </div>
        )}
      </form>
    );
  }
}

const mapStateToProps = (store) => ({
  fingerprint: store.fingerprint,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getFingerprint }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentData);

const styles = {
  cardFlag: {
    width: 50,
    height: 30,
    backgroundSize: "50px 30px",
    backgroundRepeat: "none",
  },
};
