import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import Api from "../../Services/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default class SearchCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      selectedCustomer: null,
      searchInput: "",
    };
  }

  search = async (inputValue, callback) => {
    try {
      let options = [];
      if (inputValue.length <= 2) return callback(options);
      this.setState({ searchInput: inputValue });
      let request = await Api.get("/customer/search/" + inputValue);
      options.push({ label: "default", value: "default" });
      for (let i = 0; i < request.data.customers.length; i++) {
        const customer = request.data.customers[i];
        let abbreviation = `${customer.cpf} ${customer.cnpj ? `  -  ${customer.cnpj}` : ""}`;
        options.push({
          label: customer.name,
          value: customer.id,
          customAbbreviation: abbreviation,
        });
      }
      callback(options);
    } catch (error) {
      console.log(error.response);
    }
  };

  formatOptionLabel = ({ value, label, customAbbreviation }) => {
    if (value === "default")
      return (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faPlusCircle} size="2x" className="text-primary" />
          <span className="text-primary font-weight-bold ml-2">Cadastrar: {this.state.searchInput}</span>
        </div>
      );
    return (
      <div style={{ display: "flex" }}>
        <div>{label} /</div>
        <div className="ml-2 font-weight-bold">{customAbbreviation}</div>
      </div>
    );
  };

  handleSelectChange = (selected) => {
    if (selected.value === "default") return this.props.goToRegisterCustomer(this.state.searchInput);
    this.props.onSelectCustomer(selected.value, selected.label);
  };

  render() {
    return (
      <div className="row d-flex justify-content-center mt-5 px-1">
        <div className="col-12 col-lg-10">
          <h5 className="text-primary medium">Informe os dados do seu cliente: </h5>
          <AsyncSelect
            placeholder="Nome, E-mail, CPF ou CNPJ"
            loadingMessage={() => "Buscando..."}
            noOptionsMessage={() => "Digite pelo menos 3 caracteres"}
            formatOptionLabel={this.formatOptionLabel}
            onChange={this.handleSelectChange}
            loadOptions={this.search}
          />
        </div>
      </div>
    );
  }
}
