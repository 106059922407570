import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Api from "../../Services/Api";

import "./index.css";
import { Link } from "react-router-dom";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      password: "",
      repassword: "",
      credentialError: false,
      serverError: false,
      sent: true,
    };
  }

  componentDidMount() {}

  send = async () => {
    this.setState({ requesting: true, credentialError: false, serverError: false });
    try {
      const query = new URLSearchParams(this.props.location.search);
      console.log(query.get("token"));
      let { password, repassword } = this.state;
      if (password !== repassword) return alert("Suas senhas não são iguais");
      await Api.post("/auth/password_reset", { password, repassword, token: query.get("token") });
      this.setState({ requesting: false, sent: true });
    } catch (error) {
      console.log(error.response);
      if (!error.response || (error.response.status && error.response.status !== 401)) return this.setState({ requesting: false, serverError: true });
      this.setState({ requesting: false, credentialError: true });
    }
  };

  render() {
    return (
      <div className="bg-gradient-primary login-container d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                      <div className="p-5">
                        {this.state.sent ? (
                          <div className="text-center">
                            <h4 className="font-weight-bold text-primary">Pronto!</h4>
                            <span>Sua senha foi redefinida, entre usando suas novas credenciais.</span>
                            <Link to="/login" className="d-block">
                              Voltar para login
                            </Link>
                          </div>
                        ) : (
                          <>
                            <div className="text-center">
                              <h1 className="h4 text-gray-900 mb-4">Crie uma nova senha</h1>
                            </div>
                            <form className="user">
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control form-control-user"
                                  value={this.state.password}
                                  onChange={(e) => {
                                    this.setState({ password: e.target.value });
                                  }}
                                  placeholder="Digite sua nova senha"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control form-control-user"
                                  value={this.state.repassword}
                                  onChange={(e) => {
                                    this.setState({ repassword: e.target.value });
                                  }}
                                  placeholder="Repita sua senha"
                                />
                              </div>
                              {this.state.requesting ? (
                                <div className="d-flex justify-content-center align-items-center">
                                  <FontAwesomeIcon icon={faCircleNotch} size={"2x"} spin color={"#4e73df"} />
                                </div>
                              ) : (
                                <button onClick={this.send} className="btn btn-primary btn-user btn-block">
                                  Enviar
                                </button>
                              )}
                              {this.state.serverError ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <span className="text-danger mt-2">Erro ao se comunicar com servidor</span>
                                </div>
                              ) : this.state.credentialError ? (
                                <div className="d-flex align-items-center justify-content-center">
                                  <span className="text-danger mt-2">Token inválido ou expirado</span>
                                </div>
                              ) : null}
                            </form>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
