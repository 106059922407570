import React, { Component } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faLink } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { parseMoney } from "../../Util/funcions";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./index.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    borderWidth: 0.5,
    boxShadow: "0 0 20px",
    borderRadius: 10,
    minHeight: "90%",
  },
};
ReactModal.setAppElement("#root");

export default class OrderDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPaymentType = (rawPaymentType) => {
    switch (rawPaymentType) {
      case "BOLETO":
        return "Boleto";
      case "CREDIT_CARD":
        return "Cartão de crédito";
      default:
        return "Não definido";
    }
  };

  render() {
    const { order } = this.props;
    if (!order) return null;
    const userType = localStorage.getItem("user_type");
    let myValue = 0;
    if (userType === "ADMIN") {
      myValue =
        order.payment_type === "BOLETO"
          ? order.platform_value_boleto
          : order.payment_type === "PIX"
          ? order.platform_value_pix
          : order.platform_value;
    } else {
      myValue =
        order.payment_type === "BOLETO"
          ? order.affiliate_value_boleto
          : order.payment_type === "PIX"
          ? order.affiliate_value_pix
          : order.affiliate_value;
    }

    return (
      <ReactModal isOpen={this.props.isOpen} onRequestClose={this.props.onRequestClose} style={customStyles}>
        <div className="link_modal-header d-flex justify-content-between">
          <h3>Detalhes</h3>
          <button className="btn-neutral" onClick={this.props.onRequestClose}>
            <FontAwesomeIcon icon={faTimes} size={"2x"} color={"grey"} />
          </button>
        </div>
        <hr />
        <div className="row order-data">
          <div className="col-5">
            <span>Valor</span>
          </div>
          <div className="col-7 text-right">
            <span className="text-primary font-weight-bold text-lg">R$ {parseMoney(order.value)}</span>
          </div>
          <div className="col-5">
            <span>Meu recebimento</span>
          </div>
          <div className="col-7 text-right">
            <span className="text-primary font-weight-bold text-lg">R$ {parseMoney(myValue)}</span>
          </div>
          <div className="col-5 my-3 d-flex align-items-center">
            <span>Status</span>
          </div>
          <div className="col-7 text-right my-3">
            <span className={`order-details-status-label ${order.status.toLowerCase()}`}>
              {order.status === "PAID"
                ? "PAGO"
                : order.status === "CREATED"
                ? "Aguardando pagamento"
                : order.status === "WAITING"
                ? "Processando"
                : order.status === "ANALYSIS"
                ? "Em Análise"
                : order.status === "CANCELLED"
                ? "Cancelado"
                : null}
            </span>
          </div>
          <div className="col-5 d-flex align-items-center">
            <span>Forma de pagamento</span>
          </div>
          {order.payment_type === "BOLETO" ? (
            <>
              <div className="col-7 d-flex flex-row justify-content-end align-items-center">
                <img
                  alt="Método de pagamento"
                  className="img-fluid ml-2"
                  width={40}
                  src={require(`../../Assets/Images/payment_methods/boleto.png`)}
                />
              </div>
            </>
          ) : order.payment_type === "PIX" ? (
            <>
              <div className="col-7 d-flex flex-row justify-content-end align-items-center">
                <img
                  alt="Método de pagamento"
                  className="img-fluid ml-2"
                  width={40}
                  src={require(`../../Assets/Images/payment_methods/pix.png`)}
                />
              </div>
            </>
          ) : order.payment_type === "CREDIT_CARD" && (order.status === "PAID" || order.status === "ANALYSIS" || order.status === "WAITING") ? (
            <>
              <div className="col-7 d-flex flex-row justify-content-end align-items-center">
                {order.installment_count ? <span>{order.installment_count}x em </span> : null}
                {order.card_flag && typeof order.card_flag === "string" ? (
                  <img
                    alt="Método de pagamento"
                    className="img-fluid ml-2"
                    width={40}
                    src={require(`../../Assets/Images/payment_methods/${order.card_flag.toLowerCase()}.png`)}
                  />
                ) : (
                  <span className="ml-1">bandeira não definida</span>
                )}
              </div>
            </>
          ) : (
            <div className="col-7 mt-3 d-flex flex-row justify-content-end align-items-center">
              <span>Não definido</span>
            </div>
          )}

          <div className="col-5 mt-3">
            <span>Data de criação</span>
          </div>
          <div className="col-7 text-right mt-3">
            <span>{moment(order.createdAt).format("DD/MM/YYYY - HH:mm")}</span>
          </div>
          <div className="col-5 mt-3">
            <span>Data de atualização</span>
          </div>
          <div className="col-7 text-right mt-3">
            <span>{moment(order.updatedAt).format("DD/MM/YYYY - HH:mm")}</span>
          </div>
          <div className="col-12">
            <hr />
          </div>
          <div className="col-5 mt-2">
            <span>Cliente</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span className="font-weight-bold">{order.Customer.cnpj ? order.Customer.trade_name : order.Customer.name}</span>
          </div>
          {order.Customer.cnpj ? (
            <>
              <div className="col-5 mt-2">
                <span>CNPJ</span>
              </div>
              <div className="col-7 text-right mt-2">
                <span>{order.Customer.cnpj}</span>
              </div>
              <div className="col-5 mt-2">
                <span>Responsável</span>
              </div>
              <div className="col-7 text-right mt-2">
                <span>{order.Customer.name}</span>
              </div>
            </>
          ) : null}
          <div className="col-5 mt-2">
            <span>CPF</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>{order.Customer.cpf}</span>
          </div>
          <div className="col-5 mt-2">
            <span>Email</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>{order.Customer.email}</span>
          </div>
          <div className="col-5 mt-2">
            <span>Telefone</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>
              ({order.Customer.phone_ddd}) {order.Customer.phone_number}
            </span>
          </div>
          <div className="col-5 mt-2">
            <span>Endereço</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>
              {order.Customer.street}, {order.Customer.street_number}
            </span>
          </div>
          <div className="col-5 mt-2">
            <span>Bairro</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>{order.Customer.neighborhood}</span>
          </div>
          <div className="col-5 mt-2">
            <span>Cidade - UF</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>
              {order.Customer.city} - {order.Customer.state}
            </span>
          </div>
          <div className="col-5 mt-2">
            <span>CEP</span>
          </div>
          <div className="col-7 text-right mt-2">
            <span>{order.Customer.postal_code}</span>
          </div>
          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 d-flex flex-column align-items-center justify-content-center">
            <CopyToClipboard
              text={`https://link.emepag.com.br/${order.own_code}`}
              onCopy={() => {
                toast.success("Link copiado");
              }}
            >
              <button className={`btn btn-primary btn-icon-split mt-3`} onClick={this.handleCopyLink}>
                <span className="icon text-white">
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <span className="text" id="link-url">{`link.emepag.com.br/${order.own_code}`}</span>
              </button>
            </CopyToClipboard>
            <span>Clique no botão para copiar o link</span>
          </div>
        </div>
      </ReactModal>
    );
  }
}
