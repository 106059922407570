import React, { Component } from "react";
import Api from "../../Services/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Lottie from "react-lottie";
import * as loadginAnimation from "../../Assets/Animations/loading.json";
import * as finishAnimation from "../../Assets/Animations/finish1.json";
import { toast } from "react-toastify";
import { toastError } from "../../Util/funcions";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrders } from "../../Redux/Actions";

class CreateLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderValue: 0,
      orderMaskedValue: "",
      generatingLink: false,
      linkGenerated: false,
      link: "",
      installmentCount: 0,
      username: "Buscando...",
    };
  }

  handleValueChange = (event, maskedvalue, floatvalue) => {
    this.setState({ orderMaskedValue: maskedvalue, orderValue: floatvalue });
  };

  handleGenerateLink = async () => {
    this.setState({ generatingLink: true });
    let data = {
      customer_id: this.props.customerId,
      value: this.state.orderValue,
      installment_count: this.state.installmentCount,
    };
    try {
      let request = await Api.post("/order", data);
      this.setState({ generatingLink: false, linkGenerated: true, link: `link.emepag.com.br/${request.data.order.own_code}` });
      const { getOrders } = this.props;
      getOrders();
    } catch (error) {
      this.setState({ generatingLink: false });
      toastError(error);
    }
  };

  render() {
    return (
      <div className="row text-left mt-2 mx-1">
        <div className="col-12">
          <h4 className="text-primary">Cliente</h4>
        </div>
        <div className="col-12 text-left">
          <span className="font-weight-bold text-secondary text-lg">{this.props.customerName}</span>
        </div>
        <div className="col-12">
          <hr />
        </div>
        <div className="col-12 mt-3">
          <h4 className="text-primary">Dados do pagamento</h4>
        </div>
        <div className="form-group col-md-6">
          <label>Valor total</label>
          <span className="text-danger ml-1">*</span>
          <CurrencyInput value={this.state.orderValue} onChangeEvent={this.handleValueChange} decimalSeparator="," thousandSeparator="." prefix="R$ " className="form-control" />
        </div>
        <div className="form-group col-md-6">
          <label>Parcelar em até </label>
          <span className="text-danger ml-1">*</span>
          <select
            className="form-control"
            value={this.state.installmentCount}
            onChange={(e) => {
              this.setState({ installmentCount: e.target.selectedIndex });
            }}
          >
            <option disabled value="0">
              Selecionar quantidade de parcelas
            </option>
            <option value="1">1x</option>
            <option value="2">2x</option>
            <option value="3">3x</option>
            <option value="4">4x</option>
            <option value="5">5x</option>
            <option value="6">6x</option>
            <option value="7">7x</option>
            <option value="8">8x</option>
            <option value="9">9x</option>
            <option value="10">10x</option>
            <option value="11">11x</option>
            <option value="12">12x</option>
          </select>
        </div>
        <div className="col-12">
          <hr />
        </div>
        {!this.state.linkGenerated ? (
          <div className="col-12 d-flex justify-content-center">
            <button disabled={this.state.generatingLink} className={`btn btn-primary btn-icon-split btn-lg`} onClick={this.handleGenerateLink}>
              <span className="icon text-white">
                <FontAwesomeIcon icon={faLink} />
              </span>
              <span className="text">Gerar Link</span>
            </button>
          </div>
        ) : null}
        {this.state.generatingLink ? (
          <div className="col-12 d-flex align-items-center justify-content-center flex-column mt-3">
            <Lottie options={{ loop: true, autoplay: true, animationData: loadginAnimation.default }} height={150} width={150} />
            <span>O link está sendo criado. Aguarde...</span>
          </div>
        ) : null}
        {this.state.linkGenerated ? (
          <div className="col-12 d-flex align-items-center justify-content-center flex-column mt-1">
            <Lottie options={{ loop: false, autoplay: true, animationData: finishAnimation.default }} height={120} width={120} />
            <span className="my-2 mb-1 my-md-0 mb-md-0">Pronto!</span>
            <CopyToClipboard
              text={this.state.link}
              onCopy={() => {
                toast.success("Link copiado");
              }}
            >
              <button className={`btn btn-primary btn-icon-split btn-md-lg mt-1`} onClick={this.handleCopyLink}>
                <span className="icon text-white">
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <span className="text" id="link-url">
                  {this.state.link}
                </span>
              </button>
            </CopyToClipboard>
            <span className="mt-2 text-center">Clique no botão para copiar o link de pagamento</span>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  orders: store.main.orders,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getOrders: getOrders }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateLink);
