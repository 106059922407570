export const GET_ORDERS = "GET_ORDERS";
export const SEARCH_ORDERS = "SEARCH_ORDERS";
export const LOAD_MORE_ORDERS = "LOAD_MORE_ORDERS";
export const NO_MORE_ORDERS = "NO_MORE_ORDERS";

export const GET_PAST_STATEMENTS = "GET_PAST_STATEMENTS";
export const LOAD_MORE_PAST_STATEMENTS = "LOAD_MORE_PAST_STATEMENTS";
export const NO_MORE_PAST_STATEMENTS = "NO_MORE_PAST_STATEMENTS";
export const GET_FUTURE_STATEMENTS = "GET_FUTURE_STATEMENTS";
export const LOAD_MORE_FUTURE_STATEMENTS = "LOAD_MORE_FUTURE_STATEMENTS";
export const NO_MORE_FUTURE_STATEMENTS = "NO_MORE_FUTURE_STATEMENTS";

export const REQUESTING_OPERATION = "REQUESTING_OPERATION";
export const SET_FINGERPRINT_DATA = "SET_FINGERPRINT_DATA";
export const GET_VISITOR_ID = "GET_VISITOR_ID";