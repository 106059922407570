import React, { createContext, useState, useContext } from 'react';

const TrackingContext = createContext();

export default function TrackingProvider({ children }) {
    const [tracking_code, setTracking_code] = useState("Código de rastreio não cadastrado.");

    return(
        <TrackingContext.Provider value={{tracking_code, setTracking_code}}>
            {children}
        </TrackingContext.Provider>
    );
}

export function useTracking() {
    const context = useContext(TrackingContext);
    if(!context) throw new Error("useTracking must be used within a TrackingProvider");
    const { tracking_code, setTracking_code } = context;
    return { tracking_code, setTracking_code };
}