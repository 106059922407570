import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { Link } from "react-router-dom";

export default function PaymentStatusCard(props) {
  function getStatusMessage(status) {
    switch (status) {
      case "WAITING":
        return (
          <div className="checkout-status-message-container text-center py-4 mb-4">
            <h3 className="p-2 text-primary font-weight-bold">Pagamento sendo processado</h3>
            <span>
              Seu pagamento será analisado pelos nossos operadores e logo você receberá um aviso sobre a aprovação no e-mail: <b>{props.customer.email}</b>
            </span>
          </div>
        );
      case "ANALYSIS":
        return (
          <div className="checkout-status-message-container text-center py-4 mb-4">
            <h3 className="p-2 text-primary font-weight-bold">Pagamento em análise</h3>
            <span>
              Seu pagamento será analisado pelos nossos operadores e logo você receberá um aviso sobre a aprovação no e-mail: <b>{props.customer.email}</b>
            </span>
          </div>
        );
      case "PAID":
        return (
          <div className="checkout-status-message-container text-center py-4 mb-4">
            <h3 className="p-2 text-success font-weight-bold">Pagamento realizado!</h3>
            <span>
              Seu pagamento foi efetuado com sucesso. Entre em contato com seu vendedor. Outras instruções foram enviadas para: <b>{props.customer.email}</b>
            </span>
          </div>
        );
      case "CANCELLED":
        return (
          <div className="checkout-status-message-container text-center py-4 mb-3s">
            <h3 className="p-2 text-danger font-weight-bold">Não autorizado</h3>
            <span className="d-block mb-3">
              Seu pagamento não foi autorizado pelo banco emissor do cartão. Veifique se há limite disponível ou se você digitou algum dado incorretamente.
            </span>
            <Link className={`btn btn-primary btn-icon-split btn-md align-self-center`} to={"/" + props.match.params.orderId + "?force=true"}>
              <span className="icon text-white">
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
              <span className="text">Tentar novamente</span>
            </Link>
          </div>
        );
      default:
        break;
    }
  }
  return (
    <div className="card shadow m-1 m-md-5">
      <div className="card-body">
        <h3>Status do pagamento</h3>
        <hr />
        {getStatusMessage(props.order.status)}
      </div>
    </div>
  );
}
