import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Api from "../../Services/Api";
import { toastError, parseMoney } from "../../Util/funcions";
import moment from "moment";
import OrderDetailsModal from "../../Components/OrderDetailsModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import DateRangePicker from "../../Components/DateRangePicker";
import { Collapse } from "react-collapse";
import BalanceCard from "../../Components/BalanceCard";
import RastreioModal from "../../Components/RastreioModal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrders, searchOrders } from "../../Redux/Actions";

import TrackingProvider from "../../Context/Tracking";
//import SetOwnCode from '../../Context/OwnCode/SetOwnCode';

import "./index.css";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      metrics: {
        balance: 0,
        futureBalance: 0,
      },
      searchTerm: "",
      typing: false,
      typingTimeout: 0,
      selectedOrder: null,
      loadingMore: false,
      maxReached: false,
      startDate: null,
      endDate: null,
      filterOpen: false,
      filterStatus: "ALL",
      isRastreioModalOpen: false,
      order_code: "",
      trackingSelectedOrder: "",
      trackingCode: "",
    };
  }
  componentDidMount() {
    this.getMetrics();
    const { getOrders } = this.props;
    getOrders();
  }

  openRastreioModal = (order) => {
    const { own_code } = order;
    console.log(own_code, "own_code on openrastreio_modal");
    this.setState({ trackingSelectedOrder: own_code, isRastreioModalOpen: true });
    //const { setOwn_code } = useOwnCode();
    //setOwn_code(this.state.own_code);
  };

  closeRastreioModal = () => {
    console.log(this.state.trackingSelectedOrder);
    this.setState({ isRastreioModalOpen: false });
  };

  getMetrics = async () => {
    try {
      let request = await Api.get("store/metrics");
      this.setState({ metrics: { balance: request.data.balance, futureBalance: request.data.futureBalance } });
    } catch (error) {
      toastError(error);
    }
  };

  handleSearch = (event) => {
    const { searchOrders, getOrders } = this.props;
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchTerm: event.target.value,
      typing: false,
      startDate: null,
      endDate: null,
      typingTimeout: setTimeout(async function () {
        if (self.state.searchTerm !== "") {
          let searchTerm = self.state.searchTerm;
          if (searchTerm.toLowerCase().trim().includes("pago")) searchTerm = "PAID";
          if (searchTerm.toLowerCase().trim().includes("agua")) searchTerm = "CREATED";
          if (searchTerm.toLowerCase().trim().includes("cria")) searchTerm = "CREATED";
          if (searchTerm.toLowerCase().trim().includes("cancel")) searchTerm = "CANCELLED";
          if (searchTerm.toLowerCase().trim().includes("proces")) searchTerm = "WAITING";
          if (
            searchTerm
              .toLowerCase()
              .trim()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes("anali")
          )
            searchTerm = "ANALYSIS";
          searchOrders(searchTerm);
        } else {
          getOrders();
        }
      }, 800),
    });
  };

  handleOpenOrderDetails = (order) => {
    console.log(order);
    this.setState({ selectedOrder: order, isOrderDetailsModalOpen: true });
  };

  handleOpenOrderDetailsDiv = (order) => {
    if (window.innerWidth <= 768) this.setState({ selectedOrder: order, isOrderDetailsModalOpen: true });
  };

  handleLoadMore = async () => {
    const { getOrders, offset } = this.props;
    getOrders({
      offset: offset + 50,
      startDate: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : null,
      endDate: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : null,
    });
  };

  handleDateRangeSelect = (ranges) => {
    this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate });
  };

  toggleFilter = () => {
    this.setState({ filterOpen: !this.state.filterOpen });
  };

  filter = () => {
    const { getOrders } = this.props;
    getOrders({
      offset: 0,
      startDate: this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : null,
      endDate: this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : null,
      status: this.state.filterStatus,
    });
  };

  clearFilters = () => {
    const { getOrders } = this.props;
    this.setState(
      {
        startDate: null,
        endDate: null,
        filterStatus: "ALL",
      },
      () => {
        getOrders();
      }
    );
  };

  render() {
    const userType = localStorage.getItem("user_type");
    const { orders, requesting, noMoreOrders } = this.props;
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: "selection",
    };
    return (
      <>
        <div className="mx-2 mx-md-4">
          <div className="row">
            {
              /* Cards */

              userType === "STORE_SELLER" ? null : (
                <BalanceCard Balance={parseMoney(this.state.metrics.balance)} FutureBalance={parseMoney(this.state.metrics.futureBalance)} />
              )
            }

            <div className="col-12 col-md-auto mt-4 align-items-center d-flex flex-row justify-content-between justify-content-md-start px-4">
              <h3>Extrato de pedidos</h3>
              <button className={`btn-filter ml-3 ${this.state.filterOpen ? "active" : ""}`} onClick={this.toggleFilter}>
                Filtros
              </button>
            </div>

            <div className="col-12 col-md-auto mt-1 mt-md-4 d-flex flex-row align-items-center justify-content-center">
              {!this.state.startDate || !this.state.endDate ? (
                <span>Mostrando tudo</span>
              ) : (
                <span>
                  Mostrando de {moment(this.state.startDate).format("DD/MM/YYYY")} até {moment().format("DD/MM/YYYY")}
                </span>
              )}
            </div>

            <div className="col-12 text-center text-md-left mt-2 mb-3">
              <Collapse isOpened={this.state.filterOpen}>
                <div className="row">
                  <div className="col-12 col-md-auto">
                    <DateRangePicker showPreview={false} showDateDisplay={false} ranges={[selectionRange]} onChange={this.handleDateRangeSelect} />
                  </div>
                  <div className="col-12 col-md form-group mt-3 mt-md-0">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="filter-status-select">Status</label>
                        <select
                          value={this.state.filterStatus}
                          onChange={(e) => {
                            this.setState({ filterStatus: e.target.value });
                          }}
                          className="form-control"
                        >
                          <option value="ALL">Todos</option>
                          <option value="PAID">Pago</option>
                          <option value="WAITING">Processando</option>
                          <option value="CREATED">Aguardando pagamento</option>
                          <option value="CANCELLED">Cancelado</option>
                          <option value="ANALYSIS">Em análise</option>
                        </select>
                      </div>
                      <div className="col-12 mt-4 d-flex flex-row">
                        <button className={`btn-filter`} onClick={this.filter}>
                          Filtrar
                        </button>
                        <button className={`btn-filter ml-2`} onClick={this.clearFilters}>
                          Resetar filtros
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="col-12 mt-2">
              <div className="card mb-4">
                <div className="py-3 px-md-2 d-flex flex-md-row flex-column align-items-center justify-content-end">
                  <div className="input-group col-md-4">
                    <input
                      type="text"
                      onChange={this.handleSearch}
                      className="form-control border rounded bg-light border-0"
                      placeholder="Buscar por nome, valor, status, etc..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="mx-3" />
                <div className="px-3">
                  <div className="d-flex justify-content-between align-items-center hide-on-mobile">
                    <div className="col">
                      <span className="font-weight-bold text-md">Código</span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold text-md">Cliente</span>
                    </div>
                    {userType === "ADMIN" ? (
                      <div className="col text-center">
                        <span className="font-weight-bold text-md">Seller</span>
                      </div>
                    ) : null}
                    <div className="col text-right">
                      <span className="font-weight-bold text-md mr-md-4">Forma</span>
                    </div>
                    <div className="col text-right">
                      <span className="font-weight-bold text-md mr-md-4">Valor</span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold text-md">Status</span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold text-md">Atualizado em</span>
                    </div>
                  </div>
                  <hr className="hide-on-mobile" />

                  {requesting ? (
                    <div className="text-center p-2 mb-3">BUSCANDO</div>
                  ) : orders.length === 0 ? (
                    <div className="text-center p-2 mb-3">Sem resultados</div>
                  ) : (
                    orders.map((order) => (
                      <>
                        <div
                          className="row no-gutters d-flex justify-content-between align-items-center order-container"
                          onClick={() => {
                            this.handleOpenOrderDetailsDiv(order);
                          }}
                        >
                          <div className="col-6 col-md hide-on-mobile">
                            <button
                              className="btn-neutral link-hover"
                              data-tip="Clique para ver detalhes"
                              onClick={() => {
                                this.handleOpenOrderDetails(order);
                              }}
                            >
                              <span>{order.own_code}</span>
                            </button>
                            <CopyToClipboard
                              text={`https://link.emepag.com.br/${order.own_code}`}
                              onCopy={() => {
                                toast.success("link copiado");
                              }}
                            >
                              <span data-tip="Clique para copiar o link">
                                <FontAwesomeIcon icon={faCopy} className="ml-2" />
                              </span>
                            </CopyToClipboard>
                          </div>

                          {/* Cliente */}
                          <div className="col-7 col-md order-3 order-md-2">
                            <span>{order.Customer.name}</span>
                          </div>

                          {/* Seller */}
                          {userType === "ADMIN" ? (
                            <div className="col-7 col-md order-6 order-md-2 text-left text-md-center ml-0 ml-md-4">
                              <span>{order.Store.trade_name}</span>
                            </div>
                          ) : null}

                          {/* Rastreio */}
                          {
                            <div className="col-7 col-md px-3 order-1 order-md-3 text-left text-md-right mr-0 mr-md-5">
                              <img
                                class=""
                                width={40}
                                src={require(`../../Assets/Images/payment_methods/${
                                  order.payment_type === "BOLETO"
                                    ? "boleto.png"
                                    : order.payment_type === "PIX"
                                    ? "pix.png"
                                    : order.payment_type === "CREDIT_CARD"
                                    ? order.card_flag
                                      ? `${order.card_flag.toLowerCase()}.png`
                                      : "not-found.svg"
                                    : "not-found.svg"
                                }`)}
                              />
                            </div>
                          }

                          {/* Valor */}
                          <div className="col-7 col-md order-1 order-md-3 text-left text-md-right mr-0 mr-md-5">
                            <span className="hide-on-desktop font-weight-bold order-value text-primary">R$ </span>
                            <span className="font-weight-bold order-value text-primary">{parseMoney(order.value)}</span>
                          </div>

                          {/* Status */}
                          {order.status === "CREATED" ? (
                            <div className="col-5 col-md order-4 order-status-label created text-center text-md-left order-status d-md-flex flex-md-row align-items-md-center">
                              <div className="order-status-pill created mr-2 hide-on-mobile" />
                              <span className="">Aguardando</span>
                              <span className="hide-on-mobile ml-1"> pagamento</span>
                            </div>
                          ) : order.status === "WAITING" ? (
                            <div className="col-5 col-md order-4 order-status-label waiting text-center text-md-left order-status d-md-flex flex-md-row align-items-md-center">
                              <div className="order-status-pill waiting mr-2 hide-on-mobile" />
                              <span className="">Processando</span>
                            </div>
                          ) : order.status === "PAID" ? (
                            <div className="col-5 col-md order-4 order-status-label paid text-center text-md-left order-status d-md-flex flex-md-row align-items-md-center">
                              <div className="order-status-pill paid mr-2 hide-on-mobile" />
                              <span className="">Pago</span>
                            </div>
                          ) : order.status === "ANALYSIS" ? (
                            <div className="col-5 col-md order-4 order-status-label analysis text-center text-md-left order-status d-md-flex flex-md-row align-items-md-center">
                              <div className="order-status-pill analysis mr-2 hide-on-mobile" />
                              <span className="hide-on-mobile mr-1">Em</span>
                              <span className="">análise</span>
                            </div>
                          ) : order.status === "CANCELLED" ? (
                            <div className="col-5 col-md order-4 order-status-label cancelled text-center text-md-left order-status d-md-flex flex-md-row align-items-md-center">
                              <div className="order-status-pill cancelled mr-2 hide-on-mobile" />
                              <span className="">Cancelado</span>
                            </div>
                          ) : null}

                          {/* Atualização */}
                          <div className="col-5 col-md order-2 order-md-5 text-center text-md-left">
                            <span>{moment(order.date_paid ? order.date_paid : order.updatedAt).format("DD/MM/YYYY HH:mm")}</span>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))
                  )}

                  <div className="col-12 text-center mb-3">
                    {noMoreOrders ? (
                      <span>Mostrando todos os resultados</span>
                    ) : (
                      <button className="btn-neutral btn-load-more" onClick={this.handleLoadMore}>
                        Mostrar mais {noMoreOrders}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TrackingProvider>
          <RastreioModal
            isOpen={this.state.isRastreioModalOpen}
            onRequestClose={this.closeRastreioModal}
            trackingSelectedOrder={this.state.trackingSelectedOrder}
          />
        </TrackingProvider>

        <OrderDetailsModal
          order={this.state.selectedOrder}
          isOpen={this.state.isOrderDetailsModalOpen}
          onRequestClose={() => {
            this.setState({ isOrderDetailsModalOpen: false, selectedOrder: null });
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  orders: store.main.orders,
  requesting: store.main.requesting,
  noMoreOrders: store.main.noMoreOrders,
  offset: store.main.ordersOffset,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ getOrders, searchOrders }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
