import React from "react";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/src/locale";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function RangePicker(props) {
  return (
    <DateRange
      {...props}
      locale={rdrLocales.pt}
      inputRanges={[]}
      staticRanges={
        props.staticRanges
          ? props.staticRanges
          : [
              {
                label: "Hoje",
                hasCustomRendering: true,
                range: () => ({
                  startDate: new Date(),
                  endDate: new Date(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Essa semana",
                hasCustomRendering: true,
                range: () => ({
                  startDate: moment().startOf("week"),
                  endDate: new Date(),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Esse mês",
                hasCustomRendering: true,
                range: () => ({
                  startDate: moment().startOf("month"),
                  endDate: moment().endOf("month"),
                }),
                isSelected() {
                  return false;
                },
              },
              {
                label: "Mês passado",
                hasCustomRendering: true,
                range: () => ({
                  startDate: moment().subtract(1, "month").startOf("month"),
                  endDate: moment().subtract(1, "month").endOf("month"),
                }),
                isSelected() {
                  return false;
                },
              },
            ]
      }
    />
  );
}
