import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./Services/Auth";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { Store } from "./Redux/Store";

import Orders from "./Views/Orders";
import PastStatements from "./Views/PastStatements";
import FutureStatements from "./Views/FutureStatements";
import ForgotPassword from "./Views/ForgotPassword";
import Login from "./Views/Login";
import Sidebar from "./Components/Sidebar";
import Topbar from "./Components/Topbar";
import Checkout from "./Views/Checkout";
import ResetPassword from "./Views/ResetPassword";
import ReactTooltip from "react-tooltip";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { setFingerprintData } from "./Redux/Actions";
import ConsultaRota from "./Views/Specials/ConsultaRota";

const loadFingerprint = async () => {
  try {
    const agent = await FingerprintJS.load();
    const visitorIdentifier = await agent.get();
    Store.dispatch(setFingerprintData(visitorIdentifier.visitorId));
  } catch (error) {
    console.log(error, "error getting device info");
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />)}
  />
);

const userType = localStorage.getItem("user_type");

const LoginRoute = () => {
  return isAuthenticated() ? <Redirect to={"/app"} /> : <Login />;
};

const AppRoutes = () => (
  <div id="wrapper">
    <Sidebar />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <Topbar />
        <Switch>
          <PrivateRoute exact path="/app" component={Orders} />
          <PrivateRoute exact path="/app/pagamentos" component={Orders} />
          <PrivateRoute exact path="/app/consultarota" component={ConsultaRota} />
          {userType === "STORE_SELLER" ? null : <PrivateRoute exact path="/app/extrato" component={PastStatements} />}
          {userType === "STORE_SELLER" ? null : <PrivateRoute exact path="/app/lancamentos" component={FutureStatements} />}
          <Route path="/app/*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </div>
    </div>
    <ToastContainer />
  </div>
);

const Routes = () => {
  useEffect(() => {
    loadFingerprint();
  }, []);

  return (
    <Provider store={Store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginRoute} />
          <Route exact path="/recuperar_senha" component={ForgotPassword} />
          <Route exact path="/resetar_senha" component={ResetPassword} />
          <Route path="/cadastro" component={() => <h1>SignUp</h1>} />
          <PrivateRoute exact path="/app" component={AppRoutes} />
          <PrivateRoute exact path="/app/*" component={AppRoutes} />
          <Route exact path="/:orderId" component={Checkout} />
          <PrivateRoute exact path="/" component={() => <Redirect to={{ pathname: "/app" }} />} />
          <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </BrowserRouter>
      <ReactTooltip />
    </Provider>
  );
};

export default Routes;
