import React, { Component } from "react";
import Axios from "axios";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle, faCircle, faLink, faSave } from "@fortawesome/free-solid-svg-icons";
import { toastError } from "../../Util/funcions";
import { toast } from "react-toastify";
import Api from "../../Services/Api";

export default class RegisterCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestingAddress: false,
      requestingRegister: false,
      cpfSelected: false,
      cnpjSelected: false,
      saveAndCreateLink: false,
    };
    this.streetNumberRef = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleSaveBtnClick = async (e, saveAndCreateLink) => {
    this.setState({ requestingRegister: true });
    var data = {};
    let form = new FormData(document.getElementById("customer_form"));
    form.forEach((value, key) => {
      if (value !== "") data[key] = value;
    });
    console.log(data);
    try {
      let customerRequest = await Api.post("/customer", data);
      toast.success("Cliente cadastrado");
      this.setState({ requestingRegister: false });
      document.getElementById("customer_form").reset();
      if (saveAndCreateLink) return this.props.onRegisterCustomer(customerRequest.data.customer.id, customerRequest.data.customer.name, true);
      this.props.onRegisterCustomer(customerRequest.data.customer.id, customerRequest.data.customer.name, false);
    } catch (error) {
      this.setState({ requestingRegister: false });
      return toastError(error);
    }
  };

  searchAddress = async (e) => {
    this.setState({ requestingAddress: true });
    let cep = e.target.value.replace(/^\D+/g, "");
    try {
      let addressRequest = await Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (addressRequest.data.erro) {
        this.setState({ requestingAddress: false });
        return alert("Erro ao buscar endereço com o CEP digitado");
      }
      let form = document.forms["customer_form"];
      let address = addressRequest.data;
      form.elements["street"].value = address.logradouro;
      form.elements["city"].value = address.localidade;
      form.elements["state"].value = address.uf;
      form.elements["neighborhood"].value = address.bairro;
      this.streetNumberRef.current.focus();
      this.setState({ requestingAddress: false });
    } catch (error) {
      alert("Erro ao buscar endereço com o CEP digitado");
      this.setState({ requestingAddress: false });
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="row text-left px-1" id="customer_form">
        <div className="col-12 col-md-auto">
          <button
            className={`btn btn-${this.state.cpfSelected ? "primary" : "secondary"} btn-icon-split btn-lg`}
            onClick={() => {
              this.setState({ cpfSelected: true, cnpjSelected: false });
            }}
          >
            <span className="icon text-white">
              <FontAwesomeIcon icon={this.state.cpfSelected ? faDotCircle : faCircle} />
            </span>
            <span className="text">Pessoa Física</span>
          </button>
        </div>
        <div className="col-12 col-md-auto mr-1 mt-3 mt-md-0">
          <button
            className={`btn btn-${this.state.cnpjSelected ? "primary" : "secondary"} btn-icon-split btn-lg`}
            onClick={() => {
              this.setState({ cnpjSelected: true, cpfSelected: false });
            }}
          >
            <span className="icon text-white">
              <FontAwesomeIcon icon={this.state.cnpjSelected ? faDotCircle : faCircle} />
            </span>
            <span className="text">Pessoa Jurídica</span>
          </button>
        </div>
        {this.state.cpfSelected || this.state.cnpjSelected ? (
          <>
            <div className="col-12 mt-2">
              <h4 className="text-primary">Dados pessoais</h4>
            </div>
            <div className="form-group col-md-6">
              <label>Nome do responsável</label>
              <span className="text-danger ml-1">*</span>
              <input type="text" name="name" className="form-control" defaultValue={this.props.customerName ? this.props.customerName : ""} />
            </div>
            <div className="form-group col-md-6">
              <label>CPF</label>
              <span className="text-danger ml-1">*</span>
              <InputMask mask="999.999.999-99">{(inputProps) => <input {...inputProps} type="text" name="cpf" className="form-control" />}</InputMask>
            </div>
            <div className="form-group col-md-6">
              <label>Email</label>
              <span className="text-danger ml-1">*</span>
              <input type="email" name="email" className="form-control" />
            </div>
            <div className="form-group col-md-2">
              <label>DDD</label>
              <input type="number" name="phone_ddd" maxLength={2} className="form-control" />
            </div>
            <div className="form-group col-md-4">
              <label>Número</label>
              <InputMask mask="99999-9999">{(inputProps) => <input {...inputProps} type="text" name="phone_number" className="form-control" />}</InputMask>
            </div>
            {this.state.cnpjSelected ? (
              <>
                <div className="col-12 mt-2">
                  <h4 className="text-primary">Dados da empresa</h4>
                </div>
                <div className="form-group col-md-6">
                  <label>Nome fantasia</label>
                  <input type="text" name="trade_name" className="form-control" />
                </div>
                <div className="form-group col-md-6">
                  <label>Razão social</label>
                  <input type="text" name="company_name" className="form-control" />
                </div>
                <div className="form-group col-md-6">
                  <label>CNPJ</label>
                  <InputMask mask="99.999.999/9999-99">{(inputProps) => <input {...inputProps} type="text" name="cnpj" className="form-control" />}</InputMask>
                </div>
              </>
            ) : null}
            <div className="col-12 mt-2">
              <h4 className="text-primary">Endereço</h4>
            </div>
            <div className="form-group col-md-6">
              <label>CEP</label>
              <InputMask mask="99999-999" onBlur={this.searchAddress}>
                {(inputProps) => <input {...inputProps} type="text" name="postal_code" className="form-control" />}
              </InputMask>
            </div>
            <div className="form-group col-md-6">
              <label>Cidade</label>
              <input type="text" name="city" className="form-control" disabled={this.state.requestingAddress} />
            </div>
            <div className="form-group col-md-9">
              <label>Rua</label>
              <input type="text" name="street" className="form-control" disabled={this.state.requestingAddress} />
            </div>
            <div className="form-group col-md-3">
              <label>Número</label>
              <input type="text" name="street_number" className="form-control" ref={this.streetNumberRef} />
            </div>
            <div className="form-group col-md-6">
              <label>Estado</label>
              <input type="text" name="state" maxLength={2} className="form-control" disabled={this.state.requestingAddress} />
            </div>
            <div className="form-group col-md-6">
              <label>Bairro</label>
              <input type="text" name="neighborhood" className="form-control" disabled={this.state.requestingAddress} />
            </div>
            {this.state.requestingRegister ? (
              <span>Cadastrando...</span>
            ) : (
              <>
                <div className="col-12 col-md-auto">
                  <button
                    className={`btn btn-info btn-icon-split btn-md`}
                    onClick={(e) => {
                      this.handleSaveBtnClick(e, false);
                    }}
                  >
                    <span className="icon text-white">
                      <FontAwesomeIcon icon={faSave} />
                    </span>
                    <span className="text">Apenas salvar</span>
                  </button>
                </div>
                <div className="col-12 col-md-auto mr-1 mt-3 mt-md-0">
                  <button
                    className={`btn btn-primary btn-icon-split btn-md`}
                    onClick={(e) => {
                      this.handleSaveBtnClick(e, true);
                    }}
                  >
                    <span className="icon text-white">
                      <FontAwesomeIcon icon={faLink} />
                    </span>
                    <span className="text">Salvar e criar link de pagamento</span>
                  </button>
                </div>
              </>
            )}
          </>
        ) : null}
      </form>
    );
  }
}
