import React, { Component } from "react";
import CustomerData from "../../Components/Checkout/CustomerData";
import PaymentData from "../../Components/Checkout/PaymentData";
import { Carousel } from "react-responsive-carousel";
import Lottie from "react-lottie";
import * as loadginAnimation from "../../Assets/Animations/loading.json";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

import "./index.css";
import Api from "../../Services/Api";
import moment from "moment";
import { parseMoney } from "../../Util/funcions";
import PaymentStatusCard from "../../Components/Checkout/PaymentStatusCard";

export default class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditCardSelect: true,
      cpfSelected: false,
      cnpjSelected: false,
      carouselItem: 0,
      loading: true,
      order: null,
      customerData: null,
      customer: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      let orderRequest = await Api.get("/order/id/" + this.props.match.params.orderId);
      console.log(orderRequest.data);
      let customerRequest = await Api.get("/customer/by_order_id/" + orderRequest.data.order.id);
      let customer = customerRequest.data.customer;
      this.setState({ loading: false, order: orderRequest.data.order, customer: customer });
    } catch (error) {
      alert(error);
      console.log(error);
      this.setState({ loading: false });
    }
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const force = params.get("force");
    if (this.state.loading)
      return (
        <div className="checkout-loading-container d-flex justify-content-center align-items-center">
          <Lottie options={{ loop: true, autoplay: true, animationData: loadginAnimation.default }} height={150} width={150} />
        </div>
      );
    if (!this.state.order)
      return (
        <div className="checkout-loading-container d-flex justify-content-center align-items-center">
          <h3>Pedido não encontrado</h3>
        </div>
      );

    if ((this.state.order.status !== "CREATED" && !force) || ["ANALYSIS", "PAID"].indexOf(this.state.order.status) >= 0)
      return (
        <div className="h-100">
          <div className="col-12 checkout-topbar d-flex justify-content-center align-items-center">
            <img src={require("../../Assets/Images/logo.png")} width={200} className="img-fluid" alt="logo" />
          </div>
          <div className="col-12 pt-5 d-inline d-md-flex justify-content-md-center">
            <PaymentStatusCard {...this.props} order={this.state.order} customer={this.state.customer} />
          </div>
        </div>
      );

    return (
      <div>
        <div className="col-12 checkout-topbar d-flex justify-content-center align-items-center">
          <img src={require("../../Assets/Images/logo.png")} className="img-fluid" width={200} alt="logo" />
        </div>
        <div className="checkout-wrapper px-2 px-md-4 pt-3">
          <div className="row p-1 p-md-3 mt-3 d-flex justify-content-center">
            <div className="col-12 order-2 order-md-1 col-md-8 col-lg-6">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 d-flex">
                      {this.state.carouselItem === 1 && window.innerWidth > 768 ? (
                        <button
                          className="btn-neutral"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ carouselItem: 0 });
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} className="text-primary mb-1 mr-2" size="lg" />
                        </button>
                      ) : null}
                      <h3>Dados do pagamento</h3>
                    </div>
                    <div className="col-12">
                      <div className="separator" />
                    </div>
                  </div>
                  <Carousel
                    selectedItem={this.state.carouselItem}
                    showStatus={false}
                    showIndicators={false}
                    swipeable={false}
                    showThumbs={false}
                    showArrows={false}
                    dynamicHeight={true}
                  >
                    <div>
                      <CustomerData
                        order={this.state.order}
                        next={(customerData) => {
                          this.setState({ carouselItem: 1, customerData });
                          var body = $("html, body");
                          body.stop().animate({ scrollTop: 390 }, 500, "swing");
                        }}
                      />
                    </div>
                    <div>
                      <PaymentData
                        order={this.state.order}
                        back={() => {
                          this.setState({ carouselItem: 0 });
                        }}
                        customerData={this.state.customerData}
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
            <div className="col-12 order-1 order-md-2 col-md-4 col-lg-3">
              <div className="card mb-4">
                <div className="card-body row">
                  <div className="col-12 d-flex">
                    {this.state.carouselItem === 1 && window.innerWidth <= 768 ? (
                      <button
                        className="btn-neutral"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ carouselItem: 0 });
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="text-primary mb-1 mr-2" size="lg" />
                      </button>
                    ) : null}
                    <h3>Dados do pedido</h3>
                    <hr />
                  </div>
                  <div className="col-6">Nº do pedido</div>
                  <div className="col-6 text-right font-weight-bold">{this.state.order.own_code}</div>
                  <div className="col-6">Vendedor</div>
                  <div className="col-6 text-right font-weight-bold">{this.state.order.Store.trade_name}</div>
                  <div className="col-6">Criado em</div>
                  <div className="col-6 text-right">{moment(this.state.order.createdAt).format("DD/MM/YYYY")}</div>
                  <div className="col-12 my-3">
                    <div className="separator" />
                  </div>
                  <div className="col-6">Valor</div>
                  <div className="col-6 text-primary text-right font-weight-bold">R$ {parseMoney(this.state.order.value)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
