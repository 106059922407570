import { GET_ORDERS, REQUESTING_OPERATION, NO_MORE_ORDERS } from "../Actions/actionTypes";

const initialState = {
  orders: [],
  requesting: false,
  noMoreOrders: false,
  ordersOffset: 0,
};

export const getOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      if (!action.orders) return initialState;
      let orders = action.orders;
      return {
        ...state,
        orders: action.offset !== 0 ? [...state.orders, ...orders] : action.orders,
        requesting: false,
        noMoreOrders: orders.length < 50,
        ordersOffset: action.offset,
      };
    case REQUESTING_OPERATION:
      return { ...state, requesting: true };
    case NO_MORE_ORDERS:
      return { ...state, noMoreOrders: true };
    default:
      return state;
  }
};
