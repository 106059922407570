import React, { useState, useEffect } from "react";
import Api from "../../../Services/Api";
import { getInt, parseMoney, toastError } from "../../../Util/funcions";
import { Dinero } from "../../../Util/dinero";
import moment from "moment";

export default function ConsultaRota() {
  const [statements, setStatements] = useState([]);
  const [total, setTotal] = useState(0);
  const [myShareTotal, setmyShareTotal] = useState(0);

  useEffect(() => {
    getStatements();
  }, []);

  async function getStatements() {
    try {
      const result = await Api.get(`/special/consulta_rota`);
      let statements = result.data.statements;
      setStatements(statements);
      setTotal(
        statements.reduce((sum, curr) => {
          return Dinero({ amount: getInt(curr.value) })
            .add(Dinero({ amount: getInt(sum) }))
            .toUnit();
        }, 0)
      );
      setmyShareTotal(
        statements.reduce((sum, curr) => {
          return Dinero({ amount: getInt(curr.affiliate_value) })
            .add(Dinero({ amount: getInt(sum) }))
            .toUnit();
        }, 0)
      );
    } catch (error) {
      toastError(error);
    }
  }

  return (
    <>
      <div className="mx-2 mx-md-4">
        <div className="row">
          <div className="col-12 mt-1">
            <div className="card mb-4">
              <div className="px-3 my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2>Lançamentos</h2>
                    <h5>Total: {statements.length}</h5>
                  </div>
                  <div>
                    <h3 className="my-2">Meu recebimento: R$ {parseMoney(myShareTotal)}</h3>
                    <h5 className="my-2">Total: R$ {parseMoney(total)}</h5>
                  </div>
                </div>
                <table className="table">
                  <tr>
                    <td>Pedido</td>
                    <td>Data compensação</td>
                    <td>Valor</td>
                    <td>Meu recebimento</td>
                  </tr>
                  {statements.map((statement) => (
                    <tr>
                      <td>{statement.own_code}</td>
                      <td>{moment(statement.settle_date).format("DD/MM/YYYY")}</td>
                      <td>R$ {parseMoney(statement.value)}</td>
                      <td className="font-weight-bold">R$ {parseMoney(statement.affiliate_value)}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
